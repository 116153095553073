import EventLogService from '@/services/EventLogService.js'

export const namespaced = true

export const state = {
        sent: false
    }
export const mutations = {
    SENT(state) {
        state.sent = true
    },
  }

export const actions = {
    create({ commit }, data) {

        return EventLogService.postEventLog(data).then(response => {
            commit('SENT')
            return response.data.data
        }).catch(error => {
            throw error
        })
    }
  }
